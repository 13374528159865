.main-container {
    width: 100%;
    min-width: 320px;
    height: auto;
    /* background-color: cadetblue; */
}
/* ====== SLide ====== */
/* .slider {
    width: 100%;
    height: auto;
}
.swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
}
.swiper-slide img{
    width: 100%;
    height: 100%;
}
.swiper-buttons__container {
    position: absolute;
    width: 100%;
    height: 50px;
    padding: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.swiper-buttons {
    display: flex;
    justify-content: space-between;
}
.swiper-button-next {
    position: relative;
    width: 40px;
    height: 40px;
    justify-content: center;
    border-radius: 50% ;
    background-color: var(--White);
}
.swiper-button-prev {
    position: relative;
    width: 40px;
    height: 40px;
    justify-content: center;
    border-radius: 50% ;
    background-color: var(--White);
}
.swiper-button-next img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.swiper-button-prev img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
} */

/* ====== Information ====== */
.info-container {
    width: 100%;
    height: 500px;
    padding: 20px;
    background-color: white;
}
.description-container {
    width: 100%;
    height: auto;
    margin-bottom: 25px;
}
.sub-title {
    width: 100%;
    text-align: left;
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: 8px;
    color: var(--Orange);
}
.title {
    width: 100%;
    text-align: left;
    font-size: 2.4rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: var(--Black);
}
.description {
    width: 100%;
    text-align: left;
    font-size: 1.3rem;
    line-height: 2rem;
    margin-bottom: 10px;
    color: var(--Dark-grayish-blue);
}
.price-container {
    width: 100%;
    height: auto;
}
.price-cards {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
}
.price-card--1 {
    display: flex;
}
.price-card--2 {
    display: flex;
}
.price-now {
    align-self: center;
    font-size: 2.4rem;
    font-weight: bold;
    margin-right: 15px;
}
.price-discount {
    display: flex;
    width: 40px;
    height: 25px;
    justify-content: center;
    align-self: center;
    border-radius: 5px;
    background-color: var(--Pale-orange);
}
.price-discount p {
    align-self: center;
    font-size: 1.4rem;
    font-weight: bold;
    color: var(--Orange);
}
.price-before {
    align-self: center;
    font-size: 1.4rem;
    font-weight: bold;
    text-decoration: line-through;
    color:  var(--Grayish-blue)
}
.add-container{
    width: 100%;
    height: auto;
}
.added-products {
    display: flex;
    width: 100%;
    height: 50px;
    justify-content: space-between;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 1rem;
    background-color: var(--Light-grayish-blue);
}
.button-minus {
    align-self: center;
    outline: none;
    border: none;
    background-color: transparent;
}
.products-counter {
    align-self: center;
    font-size: 1.6rem;
    font-weight: bold;
}
.button-plus {
    align-self: center;
    outline: none;
    border: none;
    background-color: transparent;
}
.add-cart-container {
    width: 100%;
    height: auto;
}
.add-cart-container button{
    width: 100%;
    height: 50px;
    outline: none;
    border: none;
    border-radius: 1rem;
    background-color: var(--Orange);
}
.add-cart {
    display: flex;
    justify-content: center;
}
.add-cart span {
    align-self: center;
    margin-right: 10px;
}
.add-cart img{
    width: 15px;
    height: 15px;
    filter: brightness(0) saturate(100%) 
    invert(91%) sepia(100%) saturate(1%) 
    hue-rotate(180deg) brightness(106%) 
    contrast(102%);
}
.add-cart p {
    align-self:center;
    font-size: 1.4rem;
    font-weight: 700;
    color: var(--White);
} 

@media (min-width: 1200px) {
    .main-container {
        display: flex;
        justify-content: center;
    }
    .main-container__items {
        display: grid;
        width: 75%;
        grid-template: repeat(2,50%) /repeat(2,50%);
        grid-template-areas:
            "area1 area2"
            "area1 area2";
        justify-items: center;
        align-items: center;
        padding-top: 3rem;
    }
    .info-container {
        grid-area: area2;
        display: flex;
        /* flex-direction: column; */
        justify-content: center;
        align-items: center;
        /* max-width: 500px; */
        padding: 0;
        background-color: none;
    }
    .info-container__items {
        max-width: 500px;
     }
     .sub-title {
        font-size: 1.4rem;
     }
     .title {
        font-size: 3.8rem;
     }
     .description {
        max-width: 370px;
        font-size: 1.4rem;
     }
     .price-cards {
         flex-direction: column;
         gap: 5px;
     }
     .add-container {
        display: flex;
        flex-direction: row;
        gap: 10px;
     }
     .added-products {
        max-width: 150px;
     }
     .add-cart-container {
        max-width: 220px;
     }
    .button-minus,
    .button-plus,
    .add-cart-container button {
        cursor: pointer;
    }
}