
/* Animacion del cart */
/* ___Entrada___ */
@-webkit-keyframes fadeInDown {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  @keyframes fadeInDown {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, -100%, 0);
      transform: translate3d(0, -100%, 0);
    }
  
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }
  .fadeInDown {
    -webkit-animation: fadeInDown .3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: fadeInDown .3s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  } 

.cart-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    position: absolute;
    width: 100%;
    height: 100px;
    top: 80px;
    background-color: transparent;
} 
.bgCart {
    width: 100%;
    height: 100%;
    z-index: -5;
    background-color: transparent;
}
.bgCartOn {
    position: fixed;
    opacity: 50%;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1; 
}
.cart-container__product {
    display: none;
    width: 90%;
    min-width: 305px;
    max-width: 370px;
    height: 280px;
    border-radius: 1rem;
    background-color: var(--White);
}
.active {
    display: block;
    z-index: 2;
    transition: all .2s ease-in-out;
    box-shadow: 7px 13px 24px 0px rgb(0,0,0,0.3);
}
.cart-header {
    display: flex;
    width: 100%;
    height: 30%;
    align-items: center;
    padding-left: 30px;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
    border-bottom: solid 1px var(--Grayish-blue);
}
.cart-header .cart-title{
    font-size: 1.8rem;
    font-weight: bold;
    color: var(--black);
}
.cart-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 70%;
    align-items: center;
    justify-content: center;
}
.cart-content .cart-empty{
    font-size: 1.6rem;
    font-weight: bold;
    color: var(--Dark-grayish-blue);
}
.disable {
    display: none;
} 
/* ===== Productos Agregados =====*/
.cart-product {
    display: none;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    /* background-color: blueviolet; */
}
.enable {
    display: flex;
}
/* __Producto y Precio__ */
.product-info {
    display: flex;
    justify-content: space-between;
    width: 90%;
    height: auto;
    margin-bottom: 15px;
}
/* Imagen */
.product-img img {
    width: 55px;
    height: 55px;
    border-radius: 0.5rem;
}
/* Descripcion */
.product-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 180px;
    height: 100%;
}
.product-title {
    font-size: 1.6rem;
    margin-bottom: 6px;
    color: var(--Dark-grayish-blue);
    overflow: hidden;
    white-space:nowrap;
    text-overflow: ellipsis
}
.product-price {
    display: flex;
    width: 100%;
}
.product-quation {
    font-size: 1.6rem;
    margin-right: 5px;
    color: var(--Dark-grayish-blue);
}
.product-amount {
    font-size: 1.6rem;
    font-weight: bold;
    margin-right: 5px;
    color: var(--Black);
}
.deleteBtn {
    display:flex;
    align-items: center;
    width: auto;
    padding-right: 5px;
    height: 100%;
}
/* __Boton Check___ */
.checkoutBtn {
    display: flex;
    justify-content: center;
    width: 90%;
    height: 50px;
    outline: none;
    border: none;
    border-radius: 1rem;
    background-color: var(--Orange);
}
.checkoutBtn p {
    align-self:center;
    font-size: 1.4rem;
    font-weight: 700;
    color: var(--White);
}
/* ===== Cart Responsive ===== */
@media (min-width: 400px) {
    .product-description {
        width: 280px;
        padding-left: 15px;
    }
}
/* ===== Tablets y iPads ==== */
@media (min-width: 700px) {
    .cart-container {
        justify-content: flex-end;
        align-items: flex-start;
        top: 60px;
        padding-right: 5%;
    }
}
/* ===== Desktop ==== */
@media (min-width: 1200px) {
    .cart-container {
        top: 80px;
    }
    .deleteBtn,
    .checkoutBtn {
        cursor: pointer;
    }
}
