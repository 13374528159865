.sidemenu {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 70%;
    /* min-width: 280px; */
    max-width: 900px;
    padding: 30px;
    padding-top: 60px;
    list-style: none;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0; 
    z-index: 1;
    margin-top: 0;
    transform: translateX(-100%) translateY(0) translateZ(0) rotate(0deg) scale(1);
    -o-transform: translateX(-100%) translateY(0) translateZ(0) rotate(0deg) scale(1);
    -ms-transform: translateX(-100%) translateY(0) translateZ(0) rotate(0deg) scale(1);
    -moz-transform: translateX(-100%) translateY(0) translateZ(0) rotate(0deg) scale(1);
    -webkit-transform: translateX(-100%) translateY(0) translateZ(0) rotate(0deg) scale(1);
    -webkit-transition: all .4s ease-out;
    transition: all .4s ease-out;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-y: none;
    background: var(--White);
} 
.activeMenu{
    z-index: 2;
    transform: translateX(0);
}
.bg {
    width: 100%;
    height: 100%;
    background-color: var(--Black);
}
.on {
    position: fixed;
    opacity: 50%;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1; 
}
.sidemenu li {
    width: 100%;
    height: 30px;
    margin-bottom: 10px;
    outline: none;
}
.sidemenu li a {
    cursor: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.6rem;
    font-weight: bold;
    text-decoration: none;
    color: var(--Black);
}

/* =============== Tablets && iPad ===============*/
/* @media (min-width: 700px) {
    nav {
        height: 100%;
    }
    .sidemenu {
        position: relative;
        flex-direction: row;
        padding: 0;
        padding-top: 0;
        height: 100%;
        transform: translateX(0);
        margin-left: 20px;
        background: none;
    }
    .bg {
        display: none;
    }
    .on {
        display: none;
    }
    .sidemenu li {
        display:flex;
        align-items: center;
        width: 60%;
        height: 100%;
        margin-right: 15px;
        margin-bottom: 0;
        outline: none;
    }
    .sidemenu li a {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.6rem;
        font-weight: normal;
        text-decoration: none;
        color: var(--Dark-grayish-blue);
    }
    .sidemenu li:hover {
        border-bottom: solid  5px var(--Orange);
    }
    .sidemenu li a:hover {
        color: var(--Black);
    } 
} */

/* =============== Desktop ===============*/
@media (min-width: 1200px) {
    nav {
        height: 100%;
    }
    .sidemenu {
        position: relative;
        flex-direction: row;
        padding: 0;
        padding-top: 0;
        height: 100%;
        transform: translateX(0);
        margin-left: 40px;
        background: none;
    }
    .bg {
        display: none;
    }
    .on {
        display: none;
    }
    .sidemenu li {
        display:flex;
        align-items: center;
        width: 60%;
        height: 100%;
        margin-right: 15px;
        margin-bottom: 0;
        outline: none;
    }
    .sidemenu li a {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.6rem;
        font-weight: normal;
        text-decoration: none;
        color: var(--Dark-grayish-blue);
    }
    .sidemenu li:hover {
        border-bottom: solid  5px var(--Orange);
    }
    .sidemenu li a:hover {
        color: var(--Black);
    } 
}
