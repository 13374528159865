:root{
  /*=== COLORS ===*/
  /*___Primary___ */
  --Orange: hsl(26, 100%, 55%);
  --Pale-orange: hsl(25, 100%, 94%);
  /*___Neutral___ */
  --Very-vark-blue: hsl(220, 13%, 13%);
  --Dark-grayish-blue: hsl(219, 9%, 45%);
  --Grayish-blue: hsl(220, 14%, 75%);
  --Light-grayish-blue: hsl(223, 64%, 98%);
  --White: hsl(0, 0%, 100%);
  --Black: hsl(0, 0%, 0%); /* (with 75% opacity for lightbox background) */
  
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  font-family: 'Kumbh Sans', sans-serif;
}
