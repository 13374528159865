.slider {
    width: 100%;
    height: auto;
}
.swiper-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: relative;
    width: 100%;
    max-width: 400px;
    height: 100%;
}
.swiper-slide img{
    width: 100%;
    height: 100%;
}
.swiper-buttons__container {
    position: absolute;
    width: 100%;
    padding: 0 10px 0;
}
.swiper-buttons {
    display: flex;
    justify-content: space-between;
}
.swiper-button-next {
    position: relative;
    width: 40px;
    height: 40px;
    justify-content: center;
    border-radius: 50% ;
    background-color: var(--White);
}
.swiper-button-prev {
    position: relative;
    width: 40px;
    height: 40px;
    justify-content: center;
    border-radius: 50% ;
    background-color: var(--White);
}
.swiper-button-next img{
    user-select: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.swiper-button-prev img{
    user-select: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.swiper-thumbs {
    display: none;
}
/* ================= Tablets y iPads ==================== */
@media (min-width: 401px) {
    .slider {
        display: flex;
        justify-content: center;
    }
    .swiper-slide img {
        border-radius: 20px;
    }    
}
/* ========================= Desktop ============================ */
@media (min-width: 1200px) {
    .slide-container {
        display: contents;
        width: 100%;
        height: auto;
    }
    .slider {
        grid-area: area1;
    }
    .swiper-main {
        display: flex;
        justify-content: center;
        flex-direction: column;
    }
    .swiper-wrapper {
        margin-bottom: 30px;
    }
    .swiper-slide img{
        cursor: pointer;
    }
    .swiper-buttons__container {
        display: none;
        padding: 0;
    }
    .swiper-thumbs {
        display: flex;
        justify-content: space-between;
     }
     .swiper-thumbs__items {
        display: flex;
        justify-content: center;
        width: 80px;
        line-height: 0;
        border-radius: 10px;
        background-color: var(--White);
     }
     .swiper-thumbs__items > img {
         cursor: pointer;
         width: 100%;
         display: block;
         user-select: none;
         border-radius: 10px;
     }
     .swiper-thumbs__items > img:hover {
        opacity: 50%;
    }
    .thumb1On, .thumb2On, .thumb3On, .thumb4On {
        border-radius: 12px;
        border: solid var(--Orange);
    }
    .thumb1On > img, 
    .thumb2On > img,
    .thumb3On > img,
    .thumb4On > img {
        opacity: 50%;
    }
}
