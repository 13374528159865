 @-webkit-keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }
  @keyframes scale-in-center {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      opacity: 1;
    }
  }

.slideModal{
    display: none;
}
@media (min-width: 1200px){
    .slideModal{
        justify-content: center;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 5;
        background-color: rgba(0, 0, 0, 0.50);
    }
    .modalActive {
        display: flex;
    }
    .slideModal-items {
        display: flex;
        width: 450px;
        flex-direction: column;
        /* background: blue; */
        align-items: flex-end;
        justify-content: center;
        -webkit-animation: scale-in-center 0.3s ease-out both;
        animation: scale-in-center 0.3s ease-out both;
    }
    .bgSlideModal {
        position: fixed;
        opacity: 50%;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 6;
    }
    .slideModal .slide-button-close {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 50px;
        height: 50px;
        /* background-color: black; */
    }
    .slideModal .slide-button-close > img {
        cursor: pointer;
        width: 20px;
        filter: brightness(0) saturate(100%) 
        invert(53%) sepia(27%) saturate(2325%) 
        hue-rotate(348deg) brightness(102%) 
        contrast(101%);
    }
    .slideModal .swiper-wrapper {
        max-width: 450px;
    }
    .slideModal .swiper-buttons__container {
        display: block;
        position: absolute;
        width: 100%;
        padding: 0 10px 0;
    }
    .slideModal .swiper-thumbs {
        display: flex;
        justify-content: space-evenly;
    }
    .slideModal .swiper-button-prev{
        cursor: pointer;
        right: 30px;
        width: 50px;
        height: 50px;
    }
    .slideModal .swiper-button-next{
        cursor: pointer;
        left: 30px;
        width: 50px;
        height: 50px;
    }
    .slideModal .swiper-button-prev img:hover{
        filter: brightness(0) saturate(100%) 
        invert(53%) sepia(27%) saturate(2325%) 
        hue-rotate(348deg) brightness(102%) 
        contrast(101%)
    }
    .slideModal .swiper-button-next img:hover{
        filter: brightness(0) saturate(100%) 
        invert(53%) sepia(27%) saturate(2325%) 
        hue-rotate(348deg) brightness(102%) 
        contrast(101%);
    }
    
}