.head-container {
    position: relative;
    display: flex;
    width: 100%;
    min-width: 320px;
    height: 70px;
    z-index: 1;
    background-color: var(--White);
}
.head-container__items {
    display: inherit;
    width: 100%;
}
.head-container__left {
    display: flex;
    width: 70%;
    max-width: 900px;
    height: 100%;
    justify-content: flex-start;
    /* background-color: blueviolet; */
}
.head-container__right {
    display: flex;
    width: 30%;
    max-width: 900px;
    height: 100%;
    justify-content: flex-end;
    /* background-color: antiquewhite; */
}
/* ==== Seccion Izquierda ==== */
.icon-menu {
    align-self: center;
}
.icon-menu img {
    width: 18px;
    height: 18px;
    margin-left: 20px;
}
.logo {
    align-self: center;
    margin-left: 15px;
}
.logo img {
    width: 95%;
}
/* ==== Seccion Derecha ==== */
.icon-cart {
    align-self: center;
}
.notification {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 13px;
    top: 8px;
    left: 8px;
    z-index: 0;
    border-radius: 40%;
    opacity: 0;
    background-color: var(--Orange);
}
.notification p {
    font-weight: bold;
    text-align: center;
    color: var(--White);
}
.notification-enable {
    opacity: 100;
    z-index: -2;
}
.icon-avatar {
    position: relative;
    align-self: center;
    z-index: -2;
}
.icon-cart img {
    position: relative;
    margin-right: 15px;
    margin-bottom: 10px;
    z-index: -3;
}
.newColor {
    filter: brightness(0) saturate(100%) 
    invert(0%) sepia(5%) saturate(7500%) 
    hue-rotate(228deg) brightness(106%) 
    contrast(106%);
}
.icon-avatar img {
    width: 28px;
    height: 28px;
    margin-right: 20px;
}
/* ======= Tablets y iPads ======= */
@media (min-width: 700px) {
    .icon-avatar {
        z-index: 0;
    }
    .icon-avatar img {
        width: 38px;
        height: 38px;
    }
    .icon-avatar img:hover {
        border: solid 2px var(--Orange);
        border-radius: 50%;
        transition: all .1s ease;
    }
}

 /* ==== Desktop ==== */
@media (min-width: 1200px) {
    .icon-menu {
        display: none;
    }
    .head-container {
        display: flex;
        justify-content: center;
        height: 100px;
    }
    .head-container__items {
        display: flex;
        justify-content: space-between;
        width: 75%;
        border-bottom: solid 1px var(--Grayish-blue);
    }
    .head-container__left {
        width: 90%;
        align-items: center;
    }
    .head-container__right {
        width: 10%;
        justify-content: space-between;
    }
    .logo {
        margin-left: 0;
    }
    .icon-cart {
        cursor: pointer;
    }
    .icon-avatar img {
        cursor: pointer;
        width: 48px;
        height: 48px;
        margin-right: 0;
    }
}
